import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { PageTitleWrapperStyles } from '../components/pageStyles'

const Policies = () => {
  function printDiv() {
    window.print()
  }

  const { policiesJson } = useStaticQuery(graphql`
    query PoliciesQuery {
      policiesJson {
        title
        content {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO pageTitle="Policies" />
      <PageTitleWrapperStyles>
        <div style={{ textAlign: 'center', alignItems: 'center' }}>
          <h4>
            Please Print & Sign Form to have ready for your first appointment.
          </h4>
          <button
            type="button"
            style={{ color: 'green' }}
            onClick={() => {
              printDiv('printThis')
              return false
            }}
          >
            PRINT
          </button>
        </div>
      </PageTitleWrapperStyles>

      <div id="printThis">
        <PageTitleWrapperStyles>
          <h3 style={{ textAlign: 'center' }}>
            The Doggie Detailer Mobile Grooming Policies and Release Form
          </h3>
        </PageTitleWrapperStyles>
        <div
          /* eslint-disable-next-line react/no-danger */
          dangerouslySetInnerHTML={{
            __html: policiesJson.content.childMarkdownRemark.html,
          }}
        />
      </div>
    </Layout>
  )
}
export default Policies
